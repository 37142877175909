<template>
  <div>
    <v-toolbar flat>
      <v-toolbar-title>Mode Analytics</v-toolbar-title>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-text-field style="width: 5px;" v-model="search" label="UserId" single-line v-on:keyup.enter="initialize" hide-details></v-text-field>
      <v-text-field style="margin-left: 5px;width: 5px;" v-model="level" label="Level" single-line v-on:keyup.enter="initialize" hide-details></v-text-field>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="dates"
        transition="scale-transition" offset-y min-width="auto">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field v-model="dates" label="Picker in menu" prepend-icon="mdi-calendar" readonly single-line
            hide-details v-bind="attrs" v-on="on"></v-text-field>
        </template>
        <v-date-picker v-model="dates" locale="tr-TR" range no-title scrollable>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="menu = false">
            Cancel
          </v-btn>
          <v-btn text color="primary" @click="initialize">
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-btn color="primary" dark @click="initialize" class="mb-2 ma-2">Search</v-btn>
    </v-toolbar>
    <v-data-table :headers="modeAnalyticDataTable" :items="$store.state.modeAnalytics.all.data"
      :items-per-page="pageItemSelect.id" hide-default-footer :loading="loading" :options.sync="options"
      loading-text="Loading... Please wait" class="elevation-1">
      <template v-slot:item.action="{ item }">
        <v-icon class="mr-2" @click="detail(item)">list</v-icon>
      </template>
      <template v-slot:item.s="{ item }">
        <div>{{ item.s | formatMoney }}</div>
      </template>
      <template v-slot:item.t="{ item }">
        <div>{{ item.t | formatMoney }}</div>
      </template>
      <template v-slot:item.fs="{ item }">
        <div>{{ item.fs | formatMoney }}</div>
      </template>
      <template v-slot:item.b="{ item }">
        <div>{{ item.b | formatMoney }}</div>
      </template>
      <template v-slot:item.f="{ item }">
        <div>{{ item.f | formatMoney }}</div>
      </template>
      <template v-slot:item.diff="{ item }">
        <div v-bind:style="{
          color: item.t - item.b > 0 ? 'lime' : '#FF6D6D',
        }">
          {{ (item.t - item.b) | formatMoney }}
        </div>
      </template>
      <template v-slot:item.winProfit="{ item }">
        <div v-bind:style="{
          color: item.t - item.b > 0 ? 'lime' : '#FF6D6D',
        }">
          {{ Math.round(((item.t - item.b) / item.b) * 100) | formatMoney }}%
        </div>
      </template>
      <!-- <template v-slot:item.c="{ item }">
        <div v-bind:style="{
          color: item.c > 100 ? 'lime' : '#FF6D6D',
        }">
          {{ item.c }}%
        </div>
      </template> -->
      <template v-slot:item.winRate="{ item }">
        <div v-bind:style="{
          color: item.t - item.b > 0 ? 'lime' : '#FF6D6D',
        }">
          {{ Math.round((item.t / item.b) * 100) / 100 }}X
        </div>
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination v-model="currentPage" @input="pageClick" :total-visible="20"
        :length="$store.state.modeAggregateLogs.all.totalPage"></v-pagination>
    </div>
    
  </div>
</template>
<script>
import "@/assets/css/site.css";
import gameType from "@/lib/gameType";

export default {
  components: {},
  name: "ModeAnalyticsCard",
  props: {
    search: {
      type: String,
    },
    level:{
      type:Number
    }
  },
  data() {
    return {
      dates: [
        new Date(Date.now())
          .addDays(-365)
          .toISOString()
          .substr(0, 10),
        new Date(Date.now()).toISOString().substr(0, 10),
      ],
      menu: false,
      dialog: false,
      detailData: [],
      pageCount: 0,
      typeSelect: { key: -999, value: "All" },
      pageItemSelect: { id: 50, value: "50" },
      loading: false,
      currentPage: 1,
      gameType: gameType,
      options: {},
      pagination: {
        sortBy: "name",
      },
      modeAnalyticDataTable: [
        { text: "Mode Id", value: "m" },
        { text: "Mode Name", value: "n" },
        { text: "Spin Count", value: "s" },
        { text: "Sum - Bet Level", value: "b" },
        { text: "Sum - Total Won Coins", value: "t" },
        { text: "Sum - Bet Win Diff", value: "diff" },
        { text: "Win Rate", value: "winRate" },
        { text: "Profit %", value: "winProfit" },
        { text: "Sum - Free Spin", value: "f" },
      ],
    };
  },
  watch: {
    options: {
      handler() {
        if (this.options.sortBy.length > 0) this.initialize();
      },
      deep: true,
    },
  },
  methods: {
    pageClick(a) {
      this.currentPage = a;
      this.initialize();
    },

    async initialize() {
      this.$refs.menu.save(this.dates);
      this.loading = true;
      await this.$store.dispatch("modeAnalytics/getAll", {
        userId: this.search,
        startDate:
          new Date(
            new Date(this.dates[0]).getTime() +
            new Date().getTimezoneOffset() * 60000
          ) / 1000,
        endDate: new Date(this.dates[1]).addDays(1).getTime() / 1000,
        level:this.level
      });
      this.loading = false;
    }
  },

  async mounted() {
    await this.initialize();
  },
};
</script>

<style>
.card-p {
  width: 142px;
  height: 155px;
  background-size: 142px 155px;
  background-repeat: no-repeat;
  border-radius: 9.5px;
  margin-right: 12px;
  margin-top: 2px;
  margin-bottom: 2px;
}

.lime-card {
  background-image: url(https://bundle.slotbase.net/assets/img/green.png);
}

.green-card {
  background-image: url(https://bundle.slotbase.net/assets/img/green.png);
}

.red-card {
  background-image: url(https://bundle.slotbase.net/assets/img/red.png);
}

.orange-card {
  background-image: url(https://bundle.slotbase.net/assets/img/orange.png);
}

.v-tooltip__content {
  opacity: 1 !important;
}

.jv-container {
  background: #0000005c !important;
}

.jsoneditor-field {
  color: #b7b3c3 !important;
}

a.jsoneditor-value.jsoneditor-url,
div.jsoneditor-value.jsoneditor-url {
  color: #00cfff !important;
}

div.jsoneditor-value.jsoneditor-array,
div.jsoneditor-value.jsoneditor-object {
  color: #b7abab !important;
}

.ace-jsoneditor .ace_variable,
.ace-jsoneditor .ace_gutter-cell {
  color: #fff !important;
}

div.jsoneditor-value.jsoneditor-string {
  color: #00cfff;
}

.ace-jsoneditor .ace_scroller,
.ace-jsoneditor .ace_gutter {
  background: #000 !important;
}
</style>
