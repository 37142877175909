<template>
  <mode-analytics-card />
</template>
<script>
import ModeAnalyticsCard from "./components/ModeAnalyticsCard.vue";
export default {
  components: {
    ModeAnalyticsCard,
  },
};
</script>
